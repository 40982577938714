import React from 'react';


import './App.css';


function Contact() {
  return (

    
    <div  >
         
    
     
  
    </div>
  );
}

export default Contact;
