import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
//import React-Bootstrap from 'react-Bootstrap';


function Home() {
  return (
   
   <h1>snwocxno</h1>
   
  
 
  );
}

export default Home;
