import React from 'react';
import Contact from './Contact.js';
import Video from './Video.js';
import Music from './Music.js';
import Home from './Home.js';
import {BrowserRouter as Router , Switch , Route} from 'react-router-dom';
import './App.css';
import Nav from './navb';
//import bootstrap from 'bootstrap';



function App() {
  return (
    <div className="App">

<Router>
  
         <div className="App" >
         <Nav />
                <input class="form-control" type="text" placeholder="Search" aria-label="Search" /> 
                <Switch>
                       <Route path="/" exact component={Home}/>
                       <Route path="/Music" component={Music}/>
                       <Route path="/Video" component={Video} />
                       <Route path="/Contact" component={Contact} />
                </Switch>
        </div>
    </Router>

    </div>
  );
}

export default App;
