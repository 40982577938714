import React from 'react';
import lopo from './lopo.png';
import './App.css';
import {Link} from 'react-router-dom';

function Nav () {

  const navStyle = {
    color:'white'
 
  };
    return (
      <div className="nav" >
         <div className=" yop">
              <img className="upo" src={lopo} alt="Lopo" height ="120" width="30%" background-color="white" /> 
          </div>
           <nav>
           <ul className="navli">
             <Link className="Link" style={navStyle} to ="/Music">
             <li>Music</li>
             </Link >
             <Link className="Link" style={navStyle} to ="/Video">
             <li>Video</li>
             </Link>
             <Link className="Link" style={navStyle} to="/Contact">
             <li>Contact</li>
             </Link>
            
           </ul>
           </nav>
          
      </div>
    );
  }
  
  export default Nav;
  
